import React from 'react'
import { useViewportSize } from '@mantine/hooks';
import checked from '../img/check-circle.svg'

type PackageRowItemProps = {
  title: string;
  subtitle?: string;
  text: string[];
  index: number;
  columns?: number;
  packageType?: string;
}

const PackageRowItem = ({ title, subtitle, text, index, columns, packageType }: PackageRowItemProps) => {

  const { width } = useViewportSize();
  const breakpoint = 640;

  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;

  const standardHeading = index === 0 && title.toLowerCase() === 'standard';
  const premiumHeading = index === 0 && title.toLowerCase() === 'premium';
  const extraHeading = index === 0 && title.toLowerCase() === 'extra';

  return (
    <div
      className={`relative overflow-hidden
      ${isMobile ? "p-3 w-1/3 row" : isDesktop && index % 2 === 0 ? 'even-row' : 'row'}
      ${columns === 1 ? 'w-full' : columns === 2 ? 'w-3/5' : columns === 3 ? 'w-2/5' : ''}
      ${index === 0 ? 'top-row bg-kb-blue text-white' : index === 1 ? 'row w-1/3' : ''}
    `}>
      {title === 'ikon'
        ? <img src={checked} alt="checked" />
        : <>
          <p className='font-bold text-lg sm:text-xl'>{title}</p>
          <p className='font-bold'>{subtitle}</p>
          {text.map((text, index) => (
            <p key={index} className='leading-6 font-normal'>{text}</p>
          ))}
        </>
      }


      {packageType === 'standard' && <div className='absolute -top-5 -right-5 rotate-45 bg-kb-yellow h-10 w-10' />}
      {packageType === 'premium' && <div className='absolute -top-5 -right-5 rotate-45 bg-kb-red h-10 w-10' />}
      {packageType === 'extra' &&
        <>
          <div className='absolute -top-8 -right-1 rotate-45 bg-kb-yellow h-10 w-10' />
          <div className='absolute -top-1 -right-8 rotate-45 bg-kb-red h-10 w-10' />
        </>
      }

      {standardHeading &&
        <div className='absolute -top-6 -right-8 rotate-45 bg-kb-yellow h-10 w-20' />
      }
      {extraHeading &&
        <>
          <div className='absolute -top-8 -right-1 rotate-45 bg-kb-yellow h-10 w-10' />
          <div className='absolute -top-1 -right-8 rotate-45 bg-kb-red h-10 w-10' />
        </>
      }
      {premiumHeading &&
        <div className='absolute -top-6 -right-8 rotate-45 bg-kb-red h-10 w-20' />
      }
    </div>
  )
}

export default PackageRowItem
