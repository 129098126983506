import React, { useEffect, useState } from 'react';
import { useViewportSize } from '@mantine/hooks';
import PackageRowItem from '../../components/PackageRowItem';
import PackagesBg from '../../img/packages-bg.svg';
import InfoText from '../../components/InfoText';
import { useLocation } from 'react-router-dom';
import { Section } from '../../types';

type Props = {
  data: Section
  packages: any
  pricingIcons: any
}

const Packages = ({ data, packages, pricingIcons }: Props) => {
  const { pathname } = useLocation();
  const { width } = useViewportSize();

  const [columns, setColumns] = useState(3);

  const breakpoint = 640;
  const isMobile = width < breakpoint;
  const isDesktop = width >= breakpoint;

  const onlyBasic = pathname === '/basic';
  const onlyStandard = pathname === '/standard';
  const onlyExtra = pathname === '/extra';
  const onlyPremium = pathname === '/premium';

  const packageSpecific = onlyBasic || onlyStandard || onlyPremium || onlyExtra;

  useEffect(() => {
    if (packageSpecific) {
      setColumns(1);
    }
    else if (pathname === '/kgd') {
      setColumns(3);
    }
    else {
      setColumns(3);
    }
  }, [packageSpecific, pathname]);

  return (
    <div className='relative max-w-screen-xl mx-auto pb-40'>
      <div className='flex flex-col items-center justify-center pt-28 my-0'>
        <div className="w-full px-6 lg:px-4">
          <div className='flex flex-col text-center border-b border-kb-blue border-opacity-20'>
            <p className='text-5xl text-kb-blue pb-5' style={{ fontWeight: 900 }}>Paket</p>
          </div>
        </div>
        <section className='flex flex-col w-full pt-28'>
          {Object.values(packages).map((row: any, index: number) => (
            <article key={index} className={`${isMobile && index === 0 ? "sticky top-0 z-50" : isMobile ? "flex flex-col" : "flex w-full"}`}>
              {row.label === "" ?
                <div className={`label-row ${isMobile ? 'hidden' : 'invisible'}`}></div> :
                <div className={`font-semibold text-black ${isMobile ? "bg-surface px-2 pt-4" : isDesktop && index % 2 === 0 ? 'even-label-row' : 'label-row'}`}>
                  <p>{row.label}</p>
                </div>}

              <div className='flex sm:w-3/4 gap-0.5'>
                {/* BASIC */}
                {(!onlyStandard && !onlyPremium && !onlyExtra) && (pathname === '/kgd' || onlyBasic) &&
                  <PackageRowItem
                    key={'basic' + index}
                    title={row.basic.title}
                    subtitle={row.basic.subtitle}
                    text={row.basic.text}
                    index={index}
                    columns={columns}
                  />
                }
                {/* STANDARD */}
                {(!onlyBasic && !onlyPremium && !onlyExtra) &&
                  <PackageRowItem
                    key={'standard' + index}
                    title={row.standard.title}
                    subtitle={row.standard.subtitle}
                    text={row.standard.text}
                    index={index}
                    columns={columns}
                  />
                }
                {/* EXTRA */}
                {(!onlyBasic && !onlyStandard && !onlyPremium) &&
                  <PackageRowItem
                    key={'extra' + index}
                    title={row.extra.title}
                    subtitle={row.extra.subtitle}
                    text={row.extra.text}
                    index={index}
                    columns={columns}
                    packageType={row.extra.packageType}
                  />
                }
                {/* PREMIUM */}
                {(!onlyBasic && !onlyStandard && !onlyExtra) &&
                  <PackageRowItem
                    key={'premium' + index}
                    title={row.premium.title}
                    subtitle={row.premium.subtitle}
                    text={row.premium.text}
                    index={index}
                    columns={columns}
                  />
                }
              </div>
            </article>
          ))}
          <p className={`font-light text-black pl-4 py-6 max-w-md`}>{data?.bottomText}</p>
        </section>
      </div>
      <section className='mt-28 max-w-screen-lg my-0 mx-auto flex flex-col'>
        {pricingIcons.content.map(({ title, text, icon }: any, index: number) => (
          <InfoText
            key={index}
            icon={icon}
            title={title}
            text={text}
          />
        ))}
      </section>
      <div className='absolute -bottom-40 -left-20 w-full'>
        <img src={PackagesBg} alt="" />
      </div>
    </div>
  )
}

export default Packages
