import { Route, Routes } from 'react-router-dom';
import Main from './Main';
import { Helmet } from 'react-helmet';
import { AppVersions } from './types';

function App() {

  const title = process.env.REACT_APP_TYPE === AppVersions.appforvaltning ? 'Förvaltningsavtal App | Kreativa ' : 'Serviceavtal | Kreativa';
  const ogTitle = process.env.REACT_APP_TYPE === AppVersions.appforvaltning ? 'Förvaltningsavtal för applikationer' : 'Serviceavtal för wordpress';
  const ogDescription = process.env.REACT_APP_TYPE === AppVersions.appforvaltning
    ? 'Låt oss ta hand om er applikation! Oavsett hur komplex eller stor applikation ni har så har vi en lösning för er, där ni kan slappna av och lita på att vi tar hand om den.'
    : 'Låt oss ta hand om er webbplats! Oavsett hur komplex eller stor webbplats ni har så har vi en lösning för er, där ni kan slappna av och lita på att vi tar hand om den.';

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
      </Helmet>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/kgd" element={<Main />} />
        <Route path="/basic" element={<Main />} />
        <Route path="/standard" element={<Main />} />
        <Route path="/extra" element={<Main />} />
        <Route path="/premium" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
