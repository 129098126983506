import React from 'react'
import InfoText from '../components/InfoText'
import GDPRBg from '../img/gdpr-bg.svg'
import { Section } from '../types'

type Props = {
  data: Section;
}

const GDPR = ({ data }: Props) => {

  if (!data.gdprIcons) return null;

  return (
    <section className='relative z-10 w-full pt-28'>
      <header className='bg-kb-blue text-white h-32 text-5xl flex justify-center items-center shadow-md'>
        <p className='-mt-2'>{data.title}</p>
      </header>
      <section className='relative max-w-screen-lg my-0 mx-auto flex flex-col pt-28'>
        {data.gdprIcons.map(({ title, text, icon }: any, index: number) => (
          <InfoText
            key={index}
            icon={icon}
            title={title}
            text={text}
            isSmall={index === (data.gdprIcons?.length ?? 0) - 1}
          />
        ))}
        <img className='absolute -bottom-60 -right-40 lg:-right-52 opacity-5' src={GDPRBg} alt="" />
      </section>
    </section>
  )
}

export default GDPR
