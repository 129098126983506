import React from 'react';
import { useViewportSize } from '@mantine/hooks';
// import { Player } from '@lottiefiles/react-lottie-player';
// import hjul from '../lotties/hjul.json';
// import moln from '../lotties/moln-1.json';
import Logo from '../img/logo_kreativa_RGB.svg';

type HomeProps = {
  title: string;
};

const Home = ({ title }: HomeProps) => {
  const { height } = useViewportSize();
  return (
    <div style={{ height }} className='relative max-w-screen-xl mx-auto px-6 flex flex-col items-center justify-center text-center'>
      <img src={Logo} alt="logo" style={{ width: 600, maxWidth: '90vw' }} />
      <p className='text-4xl sm:text-5xl uppercase font-bold pt-16 max-w-lg' style={{ lineHeight: 1.2 }}>{title}</p>
      {/* <div className="absolute bottom-1/2 translate-y-96 right-6 lg:right-32">
        <div className="relative opacity-20">
          <Player
            autoplay
            loop
            src={hjul}
            style={{ height: 80, width: 80 }}
            className='absolute -right-8 -top-4'
          />
          <Player
            autoplay
            loop
            src={moln}
            style={{ height: 180, width: 180 }}
          />
        </div>
      </div> */}
    </div>
  )
};

export default Home;
