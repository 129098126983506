import React from 'react'
import { AppVersions, Row, Section } from '../../types'
import Packages from './Packages'
import PackagesAppForvaltning from './Packages.appforvaltning'

interface Props {
  data: Section;
  packages: Row[];
  pricingIcons: Section;
}

const index: React.FC<Props> = ({ data, packages, pricingIcons }) => {
  if (process.env.REACT_APP_TYPE === AppVersions.appforvaltning) {
    return <PackagesAppForvaltning data={data} packages={packages} pricingIcons={pricingIcons} />
  } else {
    return <Packages data={data} packages={packages} pricingIcons={pricingIcons} />
  }
}

export default index
