import React from 'react'
import ExtraTopRight from '../img/extra-top-right.svg'
import PremiumTopRight from '../img/premium-top-right.svg'
import StandardTopRight from '../img/standard-top-right.svg'

type Props = {
  isBig?: boolean
  icon: string
  title: string
  text: string
  packageType: string
}

const SafetyCard = ({ isBig, icon, title, text, packageType }: Props) => {
  return (
    <div className={`w-full px-3 py-3 ${isBig ? 'sm:w-1/2' : 'sm:w-1/3'}`}>
      <article className={`relative overflow-hidden card px-8 pb-16 ${isBig ? 'pt-16 pb-24' : ''}`}>
        <div className={`w-12 h-12 ${isBig ? 'h-16 sm:h-20 w-14 sm:w-16 mb-5' : ''}`}>
          <img className='object-contain h-full w-full' src={icon} alt="" />
        </div>
        <p className={`${isBig ? 'font-bold text-2xl sm:text-3xl pb-1' : 'font-semibold text-xl text-black'}`}>{title}</p>
        <p className={`text-black text-lg font-light max-w-xs ${isBig ? 'sm:text-2xl' : ''}`}>{text}</p>

        {packageType === 'premium' &&
          <img className='absolute -top-3 -right-1' src={PremiumTopRight} alt="" />
        }
        {packageType === 'standard' &&
          <img className='absolute -top-3 -right-1' src={StandardTopRight} alt="" />
        }
        {packageType === 'extra' &&
          <img className='absolute -top-11 -right-1' src={ExtraTopRight} alt="" />
        }
      </article>
    </div>
  )
}

export default SafetyCard
