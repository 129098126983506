import React from 'react';

type ServicesProps = {
  data: any
};

const Services = ({ data }: ServicesProps) => {

  return (
    <div className='flex flex-col items-center justify-center text-center max-w-screen-xl my-0 mx-auto gap-6'>
      <p className='text-4xl sm:text-5xl font-bold'>{data.content.title}</p>
      <p className='text-xl sm:text-2xl leading-8 px-4 font-light max-w-screen-lg text-black'>{data.content.text}</p>
    </div>
  )
};

export default Services;
