import React from 'react'
import { AppVersions, PageData } from './types'
import ServiceCards from './components/ServiceCards'
import Conditions from './sections/Conditions'
import GDPR from './sections/GDPR'
import Home from './sections/Home'
import Safety from './sections/Safety'
import Services from './sections/Services'
import serviceavtal from './serviceavtal.json'
import appforvaltning from './appforvaltning.json'
import Packages from './sections/packages/index'
import { useViewportSize } from '@mantine/hooks'

const Main = () => {
  const data: PageData = process.env.REACT_APP_TYPE === AppVersions.appforvaltning ? appforvaltning : serviceavtal;
  const { height } = useViewportSize();

  const sectionTitleAndText = data.sections.find((section) => section.template === 'titleAndText');
  const sectionServiceCards = data.sections.find((section) => section.template === 'serviceCards');
  const sectionPackages = data.sections.find((section) => section.template === 'priceTable');
  const sectionPricingIcons = data.sections.find((section) => section.template === 'pricingIcons');
  const sectionGDPR = data.sections.find((section) => section.template === 'GDPRinfo');
  const sectionSafety = data.sections.find((section) => section.template === 'safety');
  const sectionConditions = data.sections.find((section) => section.template === 'conditionsInfo');

  return (
    <div style={{ height }} className="bg-surface text-kb-blue antialiased box-border overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-kb-yellow scrollbar-thumb-rounded-full scrollbar-track-surface">
      <Home title={data.title} />
      {sectionTitleAndText && <Services data={sectionTitleAndText} />}
      {sectionServiceCards && <ServiceCards data={sectionServiceCards} />}
      {sectionPackages?.rows && sectionPricingIcons &&
        <Packages
          data={sectionPackages}
          packages={sectionPackages.rows}
          pricingIcons={sectionPricingIcons}
        />
      }
      {sectionGDPR && <GDPR data={sectionGDPR} />}
      {sectionSafety && <Safety data={sectionSafety} />}
      {sectionConditions && <Conditions data={sectionConditions} />}
    </div>
  )
}

export default Main
