import React from 'react'
import VillkorBg from '../img/villkor-bg.svg'
import { Section } from '../types'

type Props = {
  data: Section;
}

const Conditions = ({ data }: Props) => {

  return (
    <section className='w-full pt-28'>
      <header className='bg-kb-blue text-white h-32 text-5xl flex justify-center items-center shadow-md'>
        <p className='-mt-2'>{data.title}</p>
      </header>

      <article className='relative max-w-screen-lg my-0 mx-auto flex flex-col pt-28 pb-60 px-4'>
        <p className='text-2xl font-semibold text-black'>{data.title}</p>
        {data.text?.map((text: string, index: number) => (
          <p key={index} className="text-xl text-black font-light" >{text}</p>
        ))}
        <img className='absolute top-20 right-0' src={VillkorBg} alt="" />
      </article>
    </section>
  )
}

export default Conditions
