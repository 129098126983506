import React from 'react'
import ServiceCard from './ServiceCard'
import { Section } from '../types'

type Props = {
  data: Section;
}

const ServiceCards = ({ data }: Props) => {

  if (!data.cards) return null;

  return (
    <section className='flex flex-wrap justify-center pt-28 max-w-screen-xl my-0 mx-auto'>
      {data.cards?.map(({ icon, title, text }: any, index: number) => (
        <ServiceCard key={index} icon={icon} index={index} title={title} text={text} />
      ))}
    </section>
  )
}

export default ServiceCards
