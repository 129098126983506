import React from 'react'

type Props = {
  index: number
  title: string
  text: string[]
  icon: string
}

const ServiceCard = ({ index, title, text, icon }: Props) => {
  const isBigCard = index === 0 || index === 1;
  return (
    <div key={index} className={`mx-3 sm:mx-0 px-3 py-3 ${isBigCard ? 'w-full sm:w-1/2' : 'w-full sm:w-1/3'}`}>
      <article className={`${isBigCard ? 'big-card' : 'card'}`}>
        <div className={`flex justify-center aspect-square ${isBigCard ? 'h-16 w-16 sm:w-32 sm:h-32' : 'h-16 w-16'}`}>
          <img className={`${isBigCard ? 'w-12 sm:w-16' : 'w-12'}`} src={icon} alt="ikon" />
        </div>
        <p className={`text-2xl font-bold ${isBigCard ? 'sm:text-3xl pb-1' : ''}`} >{title}</p>
        {text.map((text, index) =>
          <p key={index} className={`text-black text-xl font-light max-w-xs ${index === 1 ? 'pt-4' : ''} ${isBigCard ? 'sm:text-2xl' : ''}`}>
            {text}
          </p>
        )}
      </article>
    </div>
  )
}

export default ServiceCard
