export enum AppVersions {
  serviceavtal = 'SERVICEAVTAL',
  appforvaltning = 'APPFORVALTNING'
}

export interface PageData {
  title: string;
  sections: Section[];
}

export interface Section {
  id: string;
  template: string;
  content?: TitleAndText | PricingIcons[];
  cards?: PricingIcons[];
  rows?: Row[];
  title?: string;
  safetyCards?: SafetyCard[];
  moreSafetyCards?: SafetyCard[];
  gdprIcons?: PricingIcons[];
  text?: string[];
  bottomText?: string;
}

export interface SafetyCard {
  icon: string;
  title: string;
  text: string[];
  packageType: string;
}

export interface Row {
  label: string;
  basic: Basic;
  standard: Basic;
  extra: Basic;
  premium: Basic;
}

export interface Basic {
  title: string;
  text: string[];
  subtitle?: string;
}

export interface PricingIcons {
  icon: string;
  title: string;
  text: string[];
}

export interface TitleAndText {
  title: string;
  text: string;
}
