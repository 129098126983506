import React from 'react'
import SafetyCard from '../components/SafetyCard'
import { Section } from '../types'


type Props = {
  data: Section;
}

const Safety = ({ data }: Props) => {
  return (
    <section className='relative w-full pt-28 z-10'>
      <header className='bg-kb-blue text-white h-32 text-5xl flex justify-center items-center shadow-md'>
        <p className='-mt-4'>{data.title}</p>
      </header>
      {data.safetyCards &&
        <article className='flex flex-wrap justify-center w-full pt-28 max-w-screen-lg my-0 mx-auto'>
          {data.safetyCards?.map(({ title, text, icon, packageType }: any, index: number) => (
            <SafetyCard
              key={index}
              isBig={true}
              icon={icon}
              title={title}
              text={text}
              packageType={packageType}
            />
          ))}
        </article>
      }
      {data.moreSafetyCards &&
        <article className='flex flex-wrap justify-center w-full lg:pt-16 max-w-screen-lg my-0 mx-auto'>
          {data.moreSafetyCards?.map(({ title, text, icon, packageType }: any, index: number) => (
            <SafetyCard
              key={index}
              icon={icon}
              title={title}
              text={text}
              packageType={packageType}
            />
          ))}
        </article>
      }
    </section>
  )
}

export default Safety
