import React from 'react'

type Props = {
  title: string
  text: string
  icon: string
  isSmall?: boolean
}

const InfoText = ({ title, text, icon, isSmall }: Props) => {
  return (
    <article className="flex my-4 px-4">
      <div className='mx-2 h-10 aspect-square w-10 sm:w-18 rounded-full flex justify-center self-center mr-6'>
        <img className='object-contain' src={icon} alt="" />
      </div>
      <div>
        <p className='text-xl sm:text-2xl font-bold'>{title}</p>
        <p className={`font-light text-black ${isSmall ? '' : 'text-lg sm:text-xl'}`}>{text}</p>
      </div>
    </article>
  )
}

export default InfoText
